const authTypes = {
  LOG_IN_START: "LOG_IN_START",
  LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
  LOG_IN_FAILURE: "LOG_IN_FAILURE",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  CHANGE_STATUS_START: "CHANGE_STATUS_START",
  CHANGE_STATUS_SUCCESS: "CHANGE_STATUS_SUCCESS",
  CHANGE_STATUS_FAILURE: "CHANGE_STATUS_FAILURE",
  GET_CURRENT_USER_ACL_START: "GET_CURRENT_USER_ACL_START",
  GET_CURRENT_USER_ACL_SUCCESS: "GET_CURRENT_USER_ACL_SUCCESS",
  GET_CURRENT_USER_ACL_FAILURE: "GET_CURRENT_USER_ACL_FAILURE",
  UPDATE_CURRENT_USER_ACL: "UPDATE_CURRENT_USER_ACL",
  LOG_OUT: "LOG_OUT",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  USER_LOGGED_IN: "USER_LOGGED_IN",
  CLEAR_CHANGE_STATUS_ERROR_MESSAGE: "CLEAR_CHANGE_STATUS_ERROR_MESSAGE",
}

export default authTypes
