const enrollmentTypes = {
  GET_ALL_SUBJECTS_START: "GET_ALL_SUBJECTS_START",
  GET_ALL_SUBJECTS_SUCCESS: "GET_ALL_SUBJECTS_SUCCESS",
  GET_ALL_SUBJECTS_FAILURE: "GET_ALL_SUBJECTS_FAILURE",
  GET_ALL_SUBJECTS_WITH_INTAKES_START: "GET_ALL_SUBJECTS_WITH_INTAKES_START",
  GET_ALL_SUBJECTS_WITH_INTAKES_SUCCESS:
    "GET_ALL_SUBJECTS_WITH_INTAKES_SUCCESS",
  GET_ALL_SUBJECTS_WITH_INTAKES_FAILURE:
    "GET_ALL_SUBJECTS_WITH_INTAKES_FAILURE",
  CREATE_SUBJECT_START: "CREATE_SUBJECT_START",
  CREATE_SUBJECT_SUCCESS: "CREATE_SUBJECT_SUCCESS",
  CREATE_SUBJECT_FAILURE: "CREATE_SUBJECT_FAILURE",
  CREATE_ENROLLMENT_SUBJECT_START: "CREATE_ENROLLMENT_SUBJECT_START",
  CREATE_ENROLLMENT_SUBJECT_SUCCESS: "CREATE_ENROLLMENT_SUBJECT_SUCCESS",
  CREATE_ENROLLMENT_SUBJECT_FAILURE: "CREATE_ENROLLMENT_SUBJECT_FAILURE",
  CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_START:
    "CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_START",
  CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_SUCCESS:
    "CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_SUCCESS",
  CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_FAILURE:
    "CREATE_ENROLLMENT_SUBJECT_WITH_SUBJECT_DETAILS_FAILURE",
  EDIT_SUBJECT_START: "EDIT_SUBJECT_START",
  EDIT_SUBJECT_SUCCESS: "EDIT_SUBJECT_SUCCESS",
  EDIT_SUBJECT_FAILURE: "EDIT_SUBJECT_FAILURE",
  EDIT_INTAKE_SUBJECT_START: "EDIT_INTAKE_SUBJECT_START",
  EDIT_INTAKE_SUBJECT_SUCCESS: "EDIT_INTAKE_SUBJECT_SUCCESS",
  EDIT_INTAKE_SUBJECT_FAILURE: "EDIT_INTAKE_SUBJECT_FAILURE",
  DELETE_INTAKE_SUBJECT_START: "DELETE_INTAKE_SUBJECT_START",
  DELETE_INTAKE_SUBJECT_SUCCESS: "DELETE_INTAKE_SUBJECT_SUCCESS",
  DELETE_INTAKE_SUBJECT_FAILURE: "DELETE_INTAKE_SUBJECT_FAILURE",
  DELETE_SUBJECT_START: "DELETE_SUBJECT_START",
  DELETE_SUBJECT_SUCCESS: "DELETE_SUBJECT_SUCCESS",
  DELETE_SUBJECT_FAILURE: "DELETE_SUBJECT_FAILURE",
  GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_START:
    "GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_START",
  GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_SUCCESS:
    "GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_SUCCESS",
  GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_FAILURE:
    "GET_ALL_SUBJECTS_OF_SINGLE_LECTURER_FAILURE",
  ADD_SUBJECT_TO_LECTURER_START: "ADD_SUBJECT_TO_LECTURER_START",
  ADD_SUBJECT_TO_LECTURER_SUCCESS: "ADD_SUBJECT_TO_LECTURER_SUCCESS",
  ADD_SUBJECT_TO_LECTURER_FAILURE: "ADD_SUBJECT_TO_LECTURER_FAILURE",
  REMOVE_SUBJECT_FROM_LECTURER_START: "REMOVE_SUBJECT_FROM_LECTURER_START",
  REMOVE_SUBJECT_FROM_LECTURER_SUCCESS: "REMOVE_SUBJECT_FROM_LECTURER_SUCCESS",
  REMOVE_SUBJECT_FROM_LECTURER_FAILURE: "REMOVE_SUBJECT_FROM_LECTURER_FAILURE",
  GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_START:
    "GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_START",
  GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_SUCCESS:
    "GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_SUCCESS",
  GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_FAILURE:
    "GET_ALL_SUBJECTS_IN_SINGLE_INTAKE_FAILURE",
  ADD_SUBJECT_TO_INTAKE_START: "ADD_SUBJECT_TO_INTAKE_START",
  ADD_SUBJECT_TO_INTAKE_SUCCESS: "ADD_SUBJECT_TO_INTAKE_SUCCESS",
  ADD_SUBJECT_TO_INTAKE_FAILURE: "ADD_SUBJECT_TO_INTAKE_FAILURE",
  REMOVE_SUBJECT_FROM_INTAKE_START: "REMOVE_SUBJECT_FROM_INTAKE_START",
  REMOVE_SUBJECT_FROM_INTAKE_SUCCESS: "REMOVE_SUBJECT_FROM_INTAKE_SUCCESS",
  REMOVE_SUBJECT_FROM_INTAKE_FAILURE: "REMOVE_SUBJECT_FROM_INTAKE_FAILURE",
  GET_ALL_SUBJECTS_IN_COURSE_START: "GET_ALL_SUBJECTS_IN_COURSE_START",
  GET_ALL_SUBJECTS_IN_COURSE_SUCCESS: "GET_ALL_SUBJECTS_IN_COURSE_SUCCESS",
  GET_ALL_SUBJECTS_IN_COURSE_FAILURE: "GET_ALL_SUBJECTS_IN_COURSE_FAILURE",
  GET_ALL_INTAKES_IN_COURSE_START: "GET_ALL_INTAKES_IN_COURSE_START",
  GET_ALL_INTAKES_IN_COURSE_SUCCESS: "GET_ALL_INTAKES_IN_COURSE_SUCCESS",
  GET_ALL_INTAKES_IN_COURSE_FAILURE: "GET_ALL_INTAKES_IN_COURSE_FAILURE",
  GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_START:
    "GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_START",
  GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_SUCCESS:
    "GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_SUCCESS",
  GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_FAILURE:
    "GET_ALL_SUBJECTS_IN_COURSE_SEMESTER_FAILURE",
  ADD_SUBJECT_TO_COURSE_SEMESTER_START: "ADD_SUBJECT_TO_COURSE_SEMESTER_START",
  ADD_SUBJECT_TO_COURSE_SEMESTER_SUCCESS:
    "ADD_SUBJECT_TO_COURSE_SEMESTER_SUCCESS",
  ADD_SUBJECT_TO_COURSE_SEMESTER_FAILURE:
    "ADD_SUBJECT_TO_COURSE_SEMESTER_FAILURE",
  REMOVE_SUBJECT_FROM_COURSE_START: "REMOVE_SUBJECT_FROM_COURSE_START",
  REMOVE_SUBJECT_FROM_COURSE_SUCCESS: "REMOVE_SUBJECT_FROM_COURSE_SUCCESS",
  REMOVE_SUBJECT_FROM_COURSE_FAILURE: "REMOVE_SUBJECT_FROM_COURSE_FAILURE",
  GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_START:
    "GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_START",
  GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_SUCCESS:
    "GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_SUCCESS",
  GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_FAILURE:
    "GET_ALL_SUBJECTS_OF_SINGLE_STUDENT_FAILURE",
  GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_START:
    "GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_START",
  GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_SUCCESS:
    "GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_SUCCESS",
  GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_FAILURE:
    "GET_ALL_ACTIVE_SUBJECTS_OF_SINGLE_STUDENT_FAILURE",
  CHANGE_STATUS_OF_STUDENT_SUBJECT_START:
    "CHANGE_STATUS_OF_STUDENT_SUBJECT_START",
  CHANGE_STATUS_OF_STUDENT_SUBJECT_SUCCESS:
    "CHANGE_STATUS_OF_STUDENT_SUBJECT_SUCCESS",
  CHANGE_STATUS_OF_STUDENT_SUBJECT_FAILURE:
    "CHANGE_STATUS_OF_STUDENT_SUBJECT_FAILURE",
  GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_START:
    "GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_START",
  GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_SUCCESS:
    "GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_SUCCESS",
  GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_FAILURE:
    "GET_ALL_SUBJECTS_THAT_STUDENT_CAN_ENROLL_TO_FAILURE",
  ADD_SUBJECT_TO_STUDENT_START: "ADD_SUBJECT_TO_STUDENT_START",
  ADD_SUBJECT_TO_STUDENT_SUCCESS: "ADD_SUBJECT_TO_STUDENT_SUCCESS",
  ADD_SUBJECT_TO_STUDENT_FAILURE: "ADD_SUBJECT_TO_STUDENT_FAILURE",
  REMOVE_SUBJECT_FROM_STUDENT_START: "REMOVE_SUBJECT_FROM_STUDENT_START",
  REMOVE_SUBJECT_FROM_STUDENT_SUCCESS: "REMOVE_SUBJECT_FROM_STUDENT_SUCCESS",
  REMOVE_SUBJECT_FROM_STUDENT_FAILURE: "REMOVE_SUBJECT_FROM_STUDENT_FAILURE",
  GET_ALL_DEPARTMENTS_START: "GET_ALL_DEPARTMENTS_START",
  GET_ALL_DEPARTMENTS_SUCCESS: "GET_ALL_DEPARTMENTS_SUCCESS",
  GET_ALL_DEPARTMENTS_FAILURE: "GET_ALL_DEPARTMENTS_FAILURE",
  CREATE_DEPARTMENT_START: "CREATE_DEPARTMENT_START",
  CREATE_DEPARTMENT_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
  CREATE_DEPARTMENT_FAILURE: "CREATE_DEPARTMENT_FAILURE",
  EDIT_DEPARTMENT_START: "EDIT_DEPARTMENT_START",
  EDIT_DEPARTMENT_SUCCESS: "EDIT_DEPARTMENT_SUCCESS",
  EDIT_DEPARTMENT_FAILURE: "EDIT_DEPARTMENT_FAILURE",
  DELETE_DEPARTMENT_START: "DELETE_DEPARTMENT_START",
  DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
  DELETE_DEPARTMENT_FAILURE: "DELETE_DEPARTMENT_FAILURE",
  GET_ALL_COURSES_START: "GET_ALL_COURSES_START",
  GET_ALL_COURSES_SUCCESS: "GET_ALL_COURSES_SUCCESS",
  GET_ALL_COURSES_FAILURE: "GET_ALL_COURSES_FAILURE",
  GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_START:
    "GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_START",
  GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_SUCCESS:
    "GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_SUCCESS",
  GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_FAILURE:
    "GET_ALL_COURSES_IN_SINGLE_DEPARTMENT_FAILURE",
  CREATE_COURSE_START: "CREATE_COURSE_START",
  CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
  CREATE_COURSE_FAILURE: "CREATE_COURSE_FAILURE",
  EDIT_COURSE_START: "EDIT_COURSE_START",
  EDIT_COURSE_SUCCESS: "EDIT_COURSE_SUCCESS",
  EDIT_COURSE_FAILURE: "EDIT_COURSE_FAILURE",
  DELETE_COURSE_START: "DELETE_COURSE_START",
  DELETE_COURSE_SUCCESS: "DELETE_COURSE_SUCCESS",
  DELETE_COURSE_FAILURE: "DELETE_COURSE_FAILURE",
  GET_ALL_INTAKES_START: "GET_ALL_INTAKES_START",
  GET_ALL_INTAKES_SUCCESS: "GET_ALL_INTAKES_SUCCESS",
  GET_ALL_INTAKES_FAILURE: "GET_ALL_INTAKES_FAILURE",
  CREATE_INTAKE_START: "CREATE_INTAKE_START",
  CREATE_INTAKE_SUCCESS: "CREATE_INTAKE_SUCCESS",
  CREATE_INTAKE_FAILURE: "CREATE_INTAKE_FAILURE",
  ADD_STUDENT_TO_INTAKE_START: "ADD_STUDENT_TO_INTAKE_START",
  ADD_STUDENT_TO_INTAKE_SUCCESS: "ADD_STUDENT_TO_INTAKE_SUCCESS",
  ADD_STUDENT_TO_INTAKE_FAILURE: "ADD_STUDENT_TO_INTAKE_FAILURE",
  DELETE_INTAKE_START: "DELETE_INTAKE_START",
  DELETE_INTAKE_SUCCESS: "DELETE_INTAKE_SUCCESS",
  DELETE_INTAKE_FAILURE: "DELETE_INTAKE_FAILURE",
  REMOVE_STUDENT_FROM_INTAKE_START: "REMOVE_STUDENT_FROM_INTAKE_START",
  REMOVE_STUDENT_FROM_INTAKE_SUCCESS: "REMOVE_STUDENT_FROM_INTAKE_SUCCESS",
  REMOVE_STUDENT_FROM_INTAKE_FAILURE: "REMOVE_STUDENT_FROM_INTAKE_FAILURE",
  GET_INTAKE_ENROLLMENT_STATUS_START: "GET_INTAKE_ENROLLMENT_STATUS_START",
  GET_INTAKE_ENROLLMENT_STATUS_SUCCESS: "GET_INTAKE_ENROLLMENT_STATUS_SUCCESS",
  GET_INTAKE_ENROLLMENT_STATUS_FAILURE: "GET_INTAKE_ENROLLMENT_STATUS_FAILURE",
  EDIT_INTAKE_ENROLLMENT_STATUS_START: "EDIT_INTAKE_ENROLLMENT_STATUS_START",
  EDIT_INTAKE_ENROLLMENT_STATUS_SUCCESS:
    "EDIT_INTAKE_ENROLLMENT_STATUS_SUCCESS",
  EDIT_INTAKE_ENROLLMENT_STATUS_FAILURE:
    "EDIT_INTAKE_ENROLLMENT_STATUS_FAILURE",
  EDIT_INTAKE_START: "EDIT_INTAKE_START",
  EDIT_INTAKE_SUCCESS: "EDIT_INTAKE_SUCCESS",
  EDIT_INTAKE_FAILURE: "EDIT_INTAKE_FAILURE",
  GET_SELECTED_USER_INTAKES_START: "GET_SELECTED_USER_INTAKES_START",
  GET_SELECTED_USER_INTAKES_SUCCESS: "GET_SELECTED_USER_INTAKES_SUCCESS",
  GET_SELECTED_USER_INTAKES_FAILURE: "GET_SELECTED_USER_INTAKES_FAILURE",
  UPDATE_STUDENT_INTAKE_STATUS_START: "UPDATE_STUDENT_INTAKE_STATUS_START",
  UPDATE_STUDENT_INTAKE_STATUS_SUCCESS: "UPDATE_STUDENT_INTAKE_STATUS_SUCCESS",
  UPDATE_STUDENT_INTAKE_STATUS_FAILURE: "UPDATE_STUDENT_INTAKE_STATUS_FAILURE",
  GET_ALL_SUBJECTS_WITH_LECTURER_START: "GET_ALL_SUBJECTS_WITH_LECTURER_START",
  GET_ALL_SUBJECTS_WITH_LECTURER_SUCCESS:
    "GET_ALL_SUBJECTS_WITH_LECTURER_SUCCESS",
  GET_ALL_SUBJECTS_WITH_LECTURER_FAILRE:
    "GET_ALL_SUBJECTS_WITH_LECTURER_FAILRE",
  EDIT_SUBJECT_STATUS_START: "EDIT_SUBJECT_STATUS_START",
  EDIT_SUBJECT_STATUS_SUCCESS: "EDIT_SUBJECT_STATUS_SUCCESS",
  EDIT_SUBJECT_STATUS_FAILURE: "EDIT_SUBJECT_STATUS_FAILURE",
  EDIT_STUDENT_GPA_START: "EDIT_STUDENT_GPA_START",
  EDIT_STUDENT_GPA_SUCCESS: "EDIT_STUDENT_GPA_SUCCESS",
  EDIT_STUDENT_GPA_FAILURE: "EDIT_STUDENT_GPA_FAILURE",
  GET_STUDENT_CGPA_START: "GET_STUDENT_CGPA_START",
  GET_STUDENT_CGPA_SUCCESS: "GET_STUDENT_CGPA_SUCCESS",
  GET_STUDENT_CGPA_FAILURE: "GET_STUDENT_CGPA_FAILURE",
  EDIT_STUDENT_CGPA_START: "EDIT_STUDENT_CGPA_START",
  EDIT_STUDENT_CGPA_SUCCESS: "EDIT_STUDENT_CGPA_SUCCESS",
  EDIT_STUDENT_CGPA_FAILURE: "EDIT_STUDENT_CGPA_FAILURE",
  GET_ALL_INTAKES_OF_CURRENT_USER_START:
    "GET_ALL_INTAKES_OF_CURRENT_USER_START",
  GET_ALL_INTAKES_OF_CURRENT_USER_SUCCESS:
    "GET_ALL_INTAKES_OF_CURRENT_USER_SUCCESS",
  GET_ALL_INTAKES_OF_CURRENT_USER_FAILURE:
    "GET_ALL_INTAKES_OF_CURRENT_USER_FAILURE",
  GET_LECTURER_SUBJECT_START: "GET_LECTURER_SUBJECT_START",
  GET_LECTURER_SUBJECT_SUCCESS: "GET_LECTURER_SUBJECT_SUCCESS",
  GET_LECTURER_SUBJECT_FAILURE: "GET_LECTURER_SUBJECT_FAILURE",
  ADD_LECTURER_ASSISSTANT_START: "ADD_LECTURER_ASSISSTANT_START",
  ADD_LECTURER_ASSISSTANT_SUCCESS: "ADD_LECTURER_ASSISSTANT_SUCCESS",
  ADD_LECTURER_ASSISSTANT_FAILURE: "ADD_LECTURER_ASSISSTANT_FAILURE",
  REMOVE_LECTURER_ASSISSTANT_START: "REMOVE_LECTURER_ASSISSTANT_START",
  REMOVE_LECTURER_ASSISSTANT_SUCCESS: "REMOVE_LECTURER_ASSISSTANT_SUCCESS",
  REMOVE_LECTURER_ASSISSTANT_FAILURE: "REMOVE_LECTURER_ASSISSTANT_FAILURE",
  GET_CURRENT_LECTURER_ONGOING_SUBJECTS_START:
    "GET_CURRENT_LECTURER_ONGOING_SUBJECTS_START",
  GET_CURRENT_LECTURER_ONGOING_SUBJECTS_SUCCESS:
    "GET_CURRENT_LECTURER_ONGOING_SUBJECTS_SUCCESS",
  GET_CURRENT_LECTURER_ONGOING_SUBJECTS_FAILURE:
    "GET_CURRENT_LECTURER_ONGOING_SUBJECTS_FAILURE",
  CLEAR_DELETE_DEPARTMENT_ERROR_MESSAGE:
    "CLEAR_DELETE_DEPARTMENT_ERROR_MESSAGE",
  CLEAR_EDIT_DEPARTMENT_ERROR_MESSAGE: "CLEAR_EDIT_DEPARTMENT_ERROR_MESSAGE",
  CLEAR_DELETE_COURSE_ERROR_MESSAGE: "CLEAR_DELETE_COURSE_ERROR_MESSAGE",
  CLEAR_EDIT_COURSE_ERROR_MESSAGE: "CLEAR_EDIT_COURSE_ERROR_MESSAGE",
  CLEAR_DELETE_INTAKE_ERROR_MESSAGE: "CLEAR_DELETE_INTAKE_ERROR_MESSAGE",
  CLEAR_EDIT_INTAKE_ENROLLMENT_STATUS_ERROR_MESSAGE:
    "CLEAR_EDIT_INTAKE_ENROLLMENT_STATUS_ERROR_MESSAGE",
  CLEAR_DELETE_SUBJECT_ERROR_MESSAGE: "CLEAR_DELETE_SUBJECT_ERROR_MESSAGE",
  CLEAR_EDIT_SUBJECT_ERROR_MESSAGE: "CLEAR_EDIT_SUBJECT_ERROR_MESSAGE",
  CLEAR_CHANGE_STATUS_OF_STUDENT_SUBJECT_ERROR_MESSAGE:
    "CLEAR_CHANGE_STATUS_OF_STUDENT_SUBJECT_ERROR_MESSAGE",
  CLEAR_ADD_SUBJECT_TO_STUDENT_ERROR_MESSAGE:
    "CLEAR_ADD_SUBJECT_TO_STUDENT_ERROR_MESSAGE",
  CLEAR_EDIT_SUBJECT_STATUS_ERROR_MESSAGE:
    "CLEAR_EDIT_SUBJECT_STATUS_ERROR_MESSAGE",
  CLEAR_EDIT_INTAKE_ERROR_MESSAGE: "CLEAR_EDIT_INTAKE_ERROR_MESSAGE",
  CLEAR_EDIT_INTAKE_SUBJECT_ERROR_MESSAGE:
    "CLEAR_EDIT_INTAKE_SUBJECT_ERROR_MESSAGE",
  CLEAR_DELETE_INTAKE_SUBJECT_ERROR_MESSAGE:
    "CLEAR_DELETE_INTAKE_SUBJECT_ERROR_MESSAGE",
  CLEAR_EDIT_STUDENT_GPA_ERROR_MESSAGE: "CLEAR_EDIT_STUDENT_GPA_ERROR_MESSAGE",
  CLEAR_EDIT_STUDENT_CGPA_ERROR_MESSAGE:
    "CLEAR_EDIT_STUDENT_CGPA_ERROR_MESSAGE",
  CLEAR_ADD_LECTURER_ASSISSTANT_ERROR_MESSAGE:
    "CLEAR_ADD_LECTURER_ASSISSTANT_ERROR_MESSAGE",
  CLEAR_REMOVE_LECTURER_ASSISSTANT_ERROR_MESSAGE:
    "CLEAR_REMOVE_LECTURER_ASSISSTANT_ERROR_MESSAGE",
  CLEAR_REMOVE_SUBJECT_FROM_COURSE_ERROR_MESSAGE:
    "CLEAR_REMOVE_SUBJECT_FROM_COURSE_ERROR_MESSAGE",
}

export default enrollmentTypes
