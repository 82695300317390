const timetableTypes = {
  GET_ALL_CLASSES_START: "GET_ALL_CLASSES_START",
  GET_ALL_CLASSES_SUCCESS: "GET_ALL_CLASSES_SUCCESS",
  GET_ALL_CLASSES_FAILURE: "GET_ALL_CLASSES_FAILURE",
  GET_ALL_CLASSES_OF_LECTURER_START: "GET_ALL_CLASSES_OF_LECTURER_START",
  GET_ALL_CLASSES_OF_LECTURER_SUCCESS: "GET_ALL_CLASSES_OF_LECTURER_SUCCESS",
  GET_ALL_CLASSES_OF_LECTURER_FAILURE: "GET_ALL_CLASSES_OF_LECTURER_FAILURE",
  CREATE_CLASS_START: "CREATE_CLASS_START",
  CREATE_CLASS_SUCCESS: "CREATE_CLASS_SUCCESS",
  CREATE_CLASS_FAILURE: "CREATE_CLASS_FAILURE",
  UPDATE_CLASS_START: "UPDATE_CLASS_START",
  UPDATE_CLASS_SUCCESS: "UPDATE_CLASS_SUCCESS",
  UPDATE_CLASS_FAILURE: "UPDATE_CLASS_FAILURE",
  DELETE_CLASS_START: "DELETE_CLASS_START",
  DELETE_CLASS_SUCCESS: "DELETE_CLASS_SUCCESS",
  DELETE_CLASS_FAILURE: "DELETE_CLASS_FAILURE",
  CREATE_ATTENDANCE_SHEET_START: "CREATE_ATTENDANCE_SHEET_START",
  CREATE_ATTENDANCE_SHEET_SUCCESS: "CREATE_ATTENDANCE_SHEET_SUCCESS",
  CREATE_ATTENDANCE_SHEET_FAILURE: "CREATE_ATTENDANCE_SHEET_FAILURE",
  DELETE_ATTENDANCE_SHEET_START: "DELETE_ATTENDANCE_SHEET_START",
  DELETE_ATTENDANCE_SHEET_SUCCESS: "DELETE_ATTENDANCE_SHEET_SUCCESS",
  DELETE_ATTENDANCE_SHEET_FAILURE: "DELETE_ATTENDANCE_SHEET_FAILURE",
  GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_START:
    "GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_START",
  GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_SUCCESS:
    "GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_SUCCESS",
  GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_FAILURE:
    "GET_ALL_ATTENDANCE_SHEETS_IN_CLASS_FAILURE",
  GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_START:
    "GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_START",
  GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_SUCCESS:
    "GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_SUCCESS",
  GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_FAILURE:
    "GET_ALL_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_FAILURE",
  GET_ALL_STUDENTS_IN_CLASS_START: "GET_ALL_STUDENTS_IN_CLASS_START",
  GET_ALL_STUDENTS_IN_CLASS_SUCCESS: "GET_ALL_STUDENTS_IN_CLASS_SUCCESS",
  GET_ALL_STUDENTS_IN_CLASS_FAILURE: "GET_ALL_STUDENTS_IN_CLASS_FAILURE",
  UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_START:
    "UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_START",
  UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_SUCCESS:
    "UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_SUCCESS",
  UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_FAILURE:
    "UPDATE_STUDENTS_ATTENDANCES_IN_ATTENDANCE_SHEET_FAILURE",
  CREATE_ASSESSMENT_START: "CREATE_ASSESSMENT_START",
  CREATE_ASSESSMENT_SUCCESS: "CREATE_ASSESSMENT_SUCCESS",
  CREATE_ASSESSMENT_FAILURE: "CREATE_ASSESSMENT_FAILURE",
  DELETE_ASSESSMENT_START: "DELETE_ASSESSMENT_START",
  DELETE_ASSESSMENT_SUCCESS: "DELETE_ASSESSMENT_SUCCESS",
  DELETE_ASSESSMENT_FAILURE: "DELETE_ASSESSMENT_FAILURE",
  GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_START:
    "GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_START",
  GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_SUCCESS:
    "GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_SUCCESS",
  GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_FAILURE:
    "GET_ALL_ASSESSMENTS_OF_SUBJECT_FOR_LECTURER_FAILURE",
  GET_ALL_STUDENTS_MARKS_IN_SUBJECT_START:
    "GET_ALL_STUDENTS_MARKS_IN_SUBJECT_START",
  GET_ALL_STUDENTS_MARKS_IN_SUBJECT_SUCCESS:
    "GET_ALL_STUDENTS_MARKS_IN_SUBJECT_SUCCESS",
  GET_ALL_STUDENTS_MARKS_IN_SUBJECT_FAILURE:
    "GET_ALL_STUDENTS_MARKS_IN_SUBJECT_FAILURE",
  UPDATE_STUDENTS_MARKS_START: "UPDATE_STUDENTS_MARKS_START",
  UPDATE_STUDENTS_MARKS_SUCCESS: "UPDATE_STUDENTS_MARKS_SUCCESS",
  UPDATE_STUDENTS_MARKS_FAILURE: "UPDATE_STUDENTS_MARKS_FAILURE",
  UPDATE_ASSESSMENT_VISIBILITY_START: "UPDATE_ASSESSMENT_VISIBILITY_START",
  UPDATE_ASSESSMENT_VISIBILITY_SUCCESS: "UPDATE_ASSESSMENT_VISIBILITY_SUCCESS",
  UPDATE_ASSESSMENT_VISIBILITY_FAILURE: "UPDATE_ASSESSMENT_VISIBILITY_FAILURE",
  GET_AVAILABLE_LECTURERS_FOR_CLASS_START:
    "GET_AVAILABLE_LECTURERS_FOR_CLASS_START",
  GET_AVAILABLE_LECTURERS_FOR_CLASS_SUCCESS:
    "GET_AVAILABLE_LECTURERS_FOR_CLASS_SUCCESS",
  GET_AVAILABLE_LECTURERS_FOR_CLASS_FAILURE:
    "GET_AVAILABLE_LECTURERS_FOR_CLASS_FAILURE",
  GET_STUDENTS_ATTENDANCES_IN_SUBJECT_START:
    "GET_STUDENTS_ATTENDANCES_IN_SUBJECT_START",
  GET_STUDENTS_ATTENDANCES_IN_SUBJECT_SUCCESS:
    "GET_STUDENTS_ATTENDANCES_IN_SUBJECT_SUCCESS",
  GET_STUDENTS_ATTENDANCES_IN_SUBJECT_FAILURE:
    "GET_STUDENTS_ATTENDANCES_IN_SUBJECT_FAILURE",
  CLEAR_CREATE_CLASS_ERROR_MESSAGE: "CLEAR_CREATE_CLASS_ERROR_MESSAGE",
  CLEAR_UPDATE_CLASS_ERROR_MESSAGE: "CLEAR_UPDATE_CLASS_ERROR_MESSAGE",
  CLEAR_DELETE_CLASS_ERROR_MESSAGE: "CLEAR_DELETE_CLASS_ERROR_MESSAGE",
  CLEAR_CREATE_ATTENDANCE_SHEET_ERROR_MESSAGE:
    "CLEAR_CREATE_ATTENDANCE_SHEET_ERROR_MESSAGE",
  CLEAR_DELETE_ATTENDANCE_SHEET_ERROR_MESSAGE:
    "CLEAR_DELETE_ATTENDANCE_SHEET_ERROR_MESSAGE",
  CLEAR_CREATE_ASSESSMENT_ERROR_MESSAGE:
    "CLEAR_CREATE_ASSESSMENT_ERROR_MESSAGE",
  CLEAR_DELETE_ASSESSMENT_ERROR_MESSAGE:
    "CLEAR_DELETE_ASSESSMENT_ERROR_MESSAGE",
  CLEAR_UPDATE_ASSESSMENT_VISIBILITY_ERROR_MESSAGE:
    "CLEAR_UPDATE_ASSESSMENT_VISIBILITY_ERROR_MESSAGE",
  SET_SELECTED_SUBJECT: "SET_SELECTED_SUBJECT",
  SET_SELECTED_ATTENDANCE_SHEET: "SET_SELECTED_ATTENDANCE_SHEET",
  SET_SELECTED_ASSESSMENT: "SET_SELECTED_ASSESSMENT",
}

export default timetableTypes
