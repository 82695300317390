const assetsTypes = {
  GET_HOME_ASSETS_START: "GET_HOME_ASSETS_START",
  GET_HOME_ASSETS_SUCCESS: "GET_HOME_ASSETS_SUCCESS",
  GET_HOME_ASSETS_FAILURE: "GET_HOME_ASSETS_FAILURE",
  GET_EFORMS_START: "GET_EFORMS_START",
  GET_EFORMS_SUCCESS: "GET_EFORMS_SUCCESS",
  GET_EFORMS_FAILURE: "GET_EFORMS_FAILURE",
  GET_COURSE_MATERIALS_START: "GET_COURSE_MATERIALS_START",
  GET_COURSE_MATERIALS_SUCCESS: "GET_COURSE_MATERIALS_SUCCESS",
  GET_COURSE_MATERIALS_FAILURE: "GET_COURSE_MATERIALS_FAILURE",
  GET_SUBJECTS_START: "GET_SUBJECTS_START",
  GET_SUBJECTS_SUCCESS: "GET_SUBJECTS_SUCCESS",
  GET_SUBJECTS_FAILURE: "GET_SUBJECTS_FAILURE",
  CREATE_HOME_ASSET_START: "CREATE_HOME_ASSET_START",
  CREATE_HOME_ASSET_SUCCESS: "CREATE_HOME_ASSET_SUCCESS",
  CREATE_HOME_ASSET_FAILURE: "CREATE_HOME_ASSET_FAILURE",
  CREATE_EFORM_START: "CREATE_EFORM_START",
  CREATE_EFORM_SUCCESS: "CREATE_EFORM_SUCCESS",
  CREATE_EFORM_FAILURE: "CREATE_EFORM_FAILURE",
  CREATE_COURSE_MATERIAL_FOR_SUBJECT_START:
    "CREATE_COURSE_MATERIAL_FOR_SUBJECT_START",
  CREATE_COURSE_MATERIAL_FOR_SUBJECT_SUCCESS:
    "CREATE_COURSE_MATERIAL_FOR_SUBJECT_SUCCESS",
  CREATE_COURSE_MATERIAL_FOR_SUBJECT_FAILURE:
    "CREATE_COURSE_MATERIAL_FOR_SUBJECT_FAILURE",
  DELETE_HOME_ASSET_START: "DELETE_HOME_ASSET_START",
  DELETE_HOME_ASSET_SUCCESS: "DELETE_HOME_ASSET_SUCCESS",
  DELETE_HOME_ASSET_FAILURE: "DELETE_HOME_ASSET_FAILURE",
  DELETE_EFORM_START: "DELETE_EFORM_START",
  DELETE_EFORM_SUCCESS: "DELETE_EFORM_SUCCESS",
  DELETE_EFORM_FAILURE: "DELETE_EFORM_FAILURE",
  DELETE_COURSE_MATERIAL_FOR_SUBJECT_START:
    "DELETE_COURSE_MATERIAL_FOR_SUBJECT_START",
  DELETE_COURSE_MATERIAL_FOR_SUBJECT_SUCCESS:
    "DELETE_COURSE_MATERIAL_FOR_SUBJECT_SUCCESS",
  DELETE_COURSE_MATERIAL_FOR_SUBJECT_FAILURE:
    "DELETE_COURSE_MATERIAL_FOR_SUBJECT_FAILURE",
  CLEAR_CREATE_HOME_ASSET_ERROR_MESSAGE:
    "CLEAR_CREATE_HOME_ASSET_ERROR_MESSAGE",
  CLEAR_DELETE_HOME_ASSET_ERROR_MESSAGE:
    "CLEAR_DELETE_HOME_ASSET_ERROR_MESSAGE",
  CLEAR_CREATE_EFORM_ERROR_MESSAGE: "CLEAR_CREATE_EFORM_ERROR_MESSAGE",
  CLEAR_DELETE_EFORM_ERROR_MESSAGE: "CLEAR_DELETE_EFORM_ERROR_MESSAGE",
  CLEAR_CREATE_COURSE_MATERIAL_FOR_SUBJECT_ERROR_MESSAGE:
    "CLEAR_CREATE_COURSE_MATERIAL_FOR_SUBJECT_ERROR_MESSAGE",
  CLEAR_DELETE_COURSE_MATERIAL_FOR_SUBJECT_ERROR_MESSAGE:
    "CLEAR_DELETE_COURSE_MATERIAL_FOR_SUBJECT_ERROR_MESSAGE",
}

export default assetsTypes
