const financeTypes = {
  GET_STUDENT_PAYMENTS_START: "GET_STUDENT_PAYMENTS_START",
  GET_STUDENT_PAYMENTS_SUCCESS: "GET_STUDENT_PAYMENTS_SUCCESS",
  GET_STUDENT_PAYMENTS_FAILURE: "GET_STUDENT_PAYMENTS_FAILURE",
  CREATE_PAYMENT_START: "CREATE_PAYMENT_START",
  CREATE_PAYMENT_SUCCESS: "CREATE_PAYMENT_SUCCESS",
  CREATE_PAYMENT_FAILURE: "CREATE_PAYMENT_FAILURE",
  EDIT_PAYMENT_START: "EDIT_PAYMENT_START",
  EDIT_PAYMENT_SUCCESS: "EDIT_PAYMENT_SUCCESS",
  EDIT_PAYMENT_FAILURE: "EDIT_PAYMENT_FAILURE",
  DELETE_PAYMENT_START: "DELETE_PAYMENT_START",
  DELETE_PAYMENT_SUCCESS: "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_FAILURE: "DELETE_PAYMENT_FAILURE",
  CLEAR_CREATE_PAYMENT_ERROR_MESSAGE: "CLEAR_CREATE_PAYMENT_ERROR_MESSAGE",
  CLEAR_EDIT_PAYMENT_ERROR_MESSAGE: "CLEAR_EDIT_PAYMENT_ERROR_MESSAGE",
  CLEAR_DELETE_PAYMENT_ERROR_MESSAGE: "CLEAR_DELETE_PAYMENT_ERROR_MESSAGE",
}

export default financeTypes
