import React from "react"
import { useLang } from "./Metronici18n"
import { IntlProvider } from "react-intl"
import "@formatjs/intl-relativetimeformat/polyfill"
import "@formatjs/intl-relativetimeformat/locale-data/en"
import "@formatjs/intl-relativetimeformat/locale-data/ar"

import enMessages from "./messages/en"
import arMessages from "./messages/ar"

const allMessages = {
  en: enMessages,
  ar: arMessages
}

export function I18nProvider({ children }) {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}
