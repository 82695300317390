const notificationsTypes = {
  SEND_NOTIFICATION_START: "SEND_NOTIFICATION_START",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAILURE: "SEND_NOTIFICATION_FAILURE",
  SEND_NOTIFICATION_TO_USER_START: "SEND_NOTIFICATION_TO_USER_START",
  SEND_NOTIFICATION_TO_USER_SUCCESS: "SEND_NOTIFICATION_TO_USER_SUCCESS",
  SEND_NOTIFICATION_TO_USER_FAILURE: "SEND_NOTIFICATION_TO_USER_FAILURE",
  CLEAR_SEND_NOTIFICATION_ERROR_MESSAGE:
    "CLEAR_SEND_NOTIFICATION_ERROR_MESSAGE",
}

export default notificationsTypes
