const chatTypes = {
  SEND_MESSAGE_START: "SEND_MESSAGE_START",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
  START_CONVERSATION_START: "START_CONVERSATION_START",
  START_CONVERSATION_SUCCESS: "START_CONVERSATION_SUCCESS",
  START_CONVERSATION_FAILURE: "START_CONVERSATION_FAILURE",
  GET_CHATS_OF_USER_START: "GET_CHATS_OF_USER_START",
  GET_CHATS_OF_USER_SUCCESS: "GET_CHATS_OF_USER_SUCCESS",
  GET_CHATS_OF_USER_FAILURE: "GET_CHATS_OF_USER_FAILURE",
  GET_MESSAGES_OF_CHAT_START: "GET_MESSAGES_OF_CHAT_START",
  GET_MESSAGES_OF_CHAT_SUCCESS: "GET_MESSAGES_OF_CHAT_SUCCESS",
  GET_MESSAGES_OF_CHAT_FAILURE: "GET_MESSAGES_OF_CHAT_FAILURE",
  DELETE_CHAT_START: "DELETE_CHAT_START",
  DELETE_CHAT_SUCCESS: "DELETE_CHAT_SUCCESS",
  DELETE_CHAT_FAILURE: "DELETE_CHAT_FAILURE",
  RESET_CHAT_BADGE: "RESET_CHAT_BADGE",
  RESET_NEW_MESSAGE: "RESET_NEW_MESSAGE",
  CLEAR_START_CONVERSATION_ERROR_MESSAGE:
    "CLEAR_START_CONVERSATION_ERROR_MESSAGE",
  CLEAR_DELETE_CHAT_ERROR_MESSAGE: "CLEAR_DELETE_CHAT_ERROR_MESSAGE",
}

export default chatTypes
