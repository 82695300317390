const announcementTypes = {
  CREATE_ANNOUNCEMENT_START: "CREATE_ANNOUNCEMENT_START",
  CREATE_ANNOUNCEMENT_SUCCESS: "CREATE_ANNOUNCEMENT_SUCCESS",
  CREATE_ANNOUNCEMENT_FAILURE: "CREATE_ANNOUNCEMENT_FAILURE",
  GET_ANNOUNCEMENT_START: "GET_ANNOUNCEMENT_START",
  GET_ANNOUNCEMENT_SUCCESS: "GET_ANNOUNCEMENT_SUCCESS",
  GET_ANNOUNCEMENT_FAILURE: "GET_ANNOUNCEMENT_FAILURE",
  GET_ALL_ANNOUNCEMENTS_START: "GET_ALL_ANNOUNCEMENTS_START",
  GET_ALL_ANNOUNCEMENTS_SUCCESS: "GET_ALL_ANNOUNCEMENTS_SUCCESS",
  GET_ALL_ANNOUNCEMENTS_FAILURE: "GET_ALL_ANNOUNCEMENTS_FAILURE",
  GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_START:
    "GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_START",
  GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_SUCCESS:
    "GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_SUCCESS",
  GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_FAILURE:
    "GET_ALL_ANNOUNCEMENTS_FOR_LECTURER_FAILURE",
  GET_ANNOUNCEMENTS_OF_SINGAL_USER_START:
    "GET_ANNOUNCEMENTS_OF_SINGAL_USER_START",
  GET_ANNOUNCEMENTS_OF_SINGAL_USER_SUCCESS:
    "GET_ANNOUNCEMENTS_OF_SINGAL_USER_SUCCESS",
  GET_ANNOUNCEMENTS_OF_SINGAL_USER_FAILURE:
    "GET_ANNOUNCEMENTS_OF_SINGAL_USER_FAILURE",
  DELETE_ANNOUNCEMENT_START: "DELETE_ANNOUNCEMENT_START",
  DELETE_ANNOUNCEMENT_SUCCESS: "DELETE_ANNOUNCEMENT_SUCCESS",
  DELETE_ANNOUNCEMENT_FAILURE: "DELETE_ANNOUNCEMENT_FAILURE",
  EDIT_ANNOUNCEMENT_START: "EDIT_ANNOUNCEMENT_START",
  EDIT_ANNOUNCEMENT_SUCCESS: "EDIT_ANNOUNCEMENT_SUCCESS",
  EDIT_ANNOUNCEMENT_FAILURE: "EDIT_ANNOUNCEMENT_FAILURE",
  CLEAR_CREATE_ANNOUNCEMENT_ERROR_MESSAGE:
    "CLEAR_CREATE_ANNOUNCEMENT_ERROR_MESSAGE",
  CLEAR_DELETE_ANNOUNCEMENT_ERROR_MESSAGE:
    "CLEAR_DELETE_ANNOUNCEMENT_ERROR_MESSAGE",
  CLEAR_EDIT_ANNOUNCEMENT_ERROR_MESSAGE:
    "CLEAR_EDIT_ANNOUNCEMENT_ERROR_MESSAGE",
}

export default announcementTypes
