const authorizationTypes = {
	ADD_USER_TO_GROUP_START: "ADD_USER_TO_GROUP_START",
	ADD_USER_TO_GROUP_SUCCESS: "ADD_USER_TO_GROUP_SUCCESS",
	ADD_USER_TO_GROUP_FAILURE: "ADD_USER_TO_GROUP_FAILURE",
	REMOVE_USER_FROM_GROUP_START: "REMOVE_USER_FROM_GROUP_START",
	REMOVE_USER_FROM_GROUP_SUCCESS: "REMOVE_USER_GROUP_SUCCESS",
	REMOVE_USER_FROM_GROUP_FAILURE: "REMOVE_USER_FROM_GROUP_FAILURE",
	GET_GROUP_START: "GET_GROUP_START",
	GET_GROUP_SUCCESS: "GET_GROUP_SUCCESS",
	GET_GROUP_FAILURE: "GET_GROUP_FAILURE",
	GET_ALL_GROUPS_START: "GET_ALL_GROUPS_START",
	GET_ALL_GROUPS_SUCCESS: "GET_ALL_GROUPS_SUCCESS",
	GET_ALL_GROUPS_FAILURE: "GET_ALL_GROUPS_FAILURE",
	GET_GROUP_USERS_START: "GET_GROUP_USERS_START",
	GET_GROUP_USERS_SUCCESS: "GET_GROUP_USERS_SUCCESS",
	GET_GROUP_USERS_FAILURE: "GET_GROUP_USERS_FAILURE",
	CREATE_GROUP_START: "CREATE_GROUP_START",
	CREATE_GROUP_SUCCESS: "CREATE_GROUP_SUCCESS",
	CREATE_GROUP_FAILURE: "CREATE_GROUP_FAILURE",
	DELETE_GROUP_START: "DELETE_GROUP_START",
	DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
	DELETE_GROUP_FAILURE: "DELETE_GROUP_FAILURE",
	CLEAR_CREATE_GROUP_ERROR_MESSAGE: "CLEAR_CREATE_GROUP_ERROR_MESSAGE",
	CLEAR_DELETE_GROUP_ERROR_MESSAGE: "CLEAR_DELETE_GROUP_ERROR_MESSAGE",
	CLEAR_SELECTED_GROUP: "CLEAR_SELECTED_GROUP",
	CLEAR_ADD_USER_TO_GROUP_ERROR_MESSAGE: "CLEAR_ADD_USER_TO_GROUP_ERROR_MESSAGE",
	CLEAR_REMOVE_USER_FROM_GROUP_ERROR_MESSAGE: "CLEAR_REMOVE_USER_FROM_GROUP_ERROR_MESSAGE"
}

export default authorizationTypes
