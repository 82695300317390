export const consultationTypes = {
  GET_ALL_CONSULTATIONS_START: "GET_ALL_CONSULTATIONS_START",
  GET_ALL_CONSULTATIONS_SUCCESS: "GET_ALL_CONSULTATIONS_SUCCESS",
  GET_ALL_CONSULTATIONS_FAILURE: "GET_ALL_CONSULTATIONS_FAILURE",
  GET_SELECTED_USER_CONSULTATIONS_START:
    "GET_SELECTED_USER_CONSULTATIONS_START",
  GET_SELECTED_USER_CONSULTATIONS_SUCCESS:
    "GET_SELECTED_USER_CONSULTATIONS_SUCCESS",
  GET_SELECTED_USER_CONSULTATIONS_FAILURE:
    "GET_SELECTED_USER_CONSULTATIONS_FAILURE",
  GET_CURRENT_USER_CONSULTATIONS_START: "GET_CURRENT_USER_CONSULTATIONS_START",
  GET_CURRENT_USER_CONSULTATIONS_SUCCESS:
    "GET_CURRENT_USER_CONSULTATIONS_SUCCESS",
  GET_CURRENT_USER_CONSULTATIONS_FAILURE:
    "GET_CURRENT_USER_CONSULTATIONS_FAILURE",
  CREATE_CONSULTATION_START: "CREATE_CONSULTATION_START",
  CREATE_CONSULTATION_SUCCESS: "CREATE_CONSULTATION_SUCCESS",
  CREATE_CONSULTATION_FAILURE: "CREATE_CONSULTATION_FAILURE",
  DELETE_CONSULTATION_START: "DELETE_CONSULTATION_START",
  DELETE_CONSULTATION_SUCCESS: "DELETE_CONSULTATION_SUCCESS",
  DELETE_CONSULTATION_FAILURE: "DELETE_CONSULTATION_FAILURE",
  RESET_CONSULTATION_BADGE: "RESET_CONSULTATION_BADGE",
  CLEAR_CREATE_CONSULTATION_ERROR_MESSAGE:
    "CLEAR_CREATE_CONSULTATION_ERROR_MESSAGE",
  CLEAR_DELETE_CONSULTATION_ERROR_MESSAGE:
    "CLEAR_DELETE_CONSULTATION_ERROR_MESSAGE",
}
