const followUpTypes = {
  GET_FOLLOW_UP_START: "GET_FOLLOW_UP_START",
  GET_FOLLOW_UP_SUCCESS: "GET_FOLLOW_UP_SUCCESS",
  GET_FOLLOW_UP_FAILURE: "GET_FOLLOW_UP_FAILURE",
  GET_ALL_FOLLOW_UPS_START: "GET_ALL_FOLLOW_UPS_START",
  GET_ALL_FOLLOW_UPS_SUCCESS: "GET_ALL_FOLLOW_UPS_SUCCESS",
  GET_ALL_FOLLOW_UPS_FAILURE: "GET_ALL_FOLLOW_UPS_FAILURE",
  GET_FOLLOW_UPS_OF_SINGAL_USER_START: "GET_FOLLOW_UP_OF_SINGAL_USER_START",
  GET_FOLLOW_UPS_OF_SINGAL_USER_SUCCESS: "GET_FOLLOW_UP_OF_SINGAL_USER_SUCCESS",
  GET_FOLLOW_UPS_OF_SINGAL_USER_FAILURE: "GET_FOLLOW_UP_OF_SINGAL_USER_FAILURE",
  CREATE_FOLLOW_UP_START: "CREATE_FOLLOW_UP_START",
  CREATE_FOLLOW_UP_SUCCESS: "CREATE_FOLLOW_UP_SUCCESS",
  CREATE_FOLLOW_UP_FAILURE: "CREATE_FOLLOW_UP_FAILURE",
  DELETE_FOLLOW_UP_START: "DELETE_FOLLOW_UP_START",
  DELETE_FOLLOW_UP_SUCCESS: "DELETE_FOLLOW_UP_SUCCESS",
  DELETE_FOLLOW_UP_FAILURE: "DELETE_FOLLOW_UP_FAILURE",
  EDIT_FOLLOW_UP_START: "EDIT_FOLLOW_UP_START",
  EDIT_FOLLOW_UP_SUCCESS: "EDIT_FOLLOW_UP_SUCCESS",
  EDIT_FOLLOW_UP_FAILURE: "EDIT_FOLLOW_UP_FAILURE",
  CLEAR_CREATE_FOLLOW_UP_ERROR_MESSAGE: "CLEAR_CREATE_FOLLOW_UP_ERROR_MESSAGE",
  CLEAR_DELETE_FOLLOW_UP_ERROR_MESSAGE: "CLEAR_DELETE_FOLLOW_UP_ERROR_MESSAGE",
  CLEAR_EDIT_FOLLOW_UP_ERROR_MESSAGE: "CLEAR_EDIT_FOLLOW_UP_ERROR_MESSAGE",
}

export default followUpTypes
